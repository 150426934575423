import React from 'react';
import classNames from 'classnames/bind';

import styles from './FormRow.module.scss';

const cx = classNames.bind(styles);

const FormRow = ({ children, title, required }) => {
  return (
    <div className={cx('container')}>
      <div className={cx(['cell', 'header'])}>
        {title}
        {required && <sup>*</sup>}
      </div>
      <div className={cx(['cell', 'body'])}>{children}</div>
    </div>
  );
};

export default FormRow;
