import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { IoCloseOutline } from 'react-icons/io5';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from './SearchAccount.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';

const cx = classNames.bind(styles);

const Modal = ({ callback }) => {
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    API.get('/accounts').then(({ success, data }) => {
      setAccounts(success ? data : []);
    });
  }, []);

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      width: 120,
      align: 'center',
      onPress: callback,
    },
    {
      key: 'email',
      name: 'Email',
      exp: (item) => item.email ?? '-',
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'robots',
      name: 'Robots',
      exp: (item) => commaNumber(item.robots.length),
      width: 88,
      align: 'center',
      onPress: callback,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Accounts</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={accounts} />
      </div>
    </div>
  );
};

export default Modal;
