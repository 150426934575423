import React from 'react';
import classNames from 'classnames/bind';

import styles from './TextInput.module.scss';

const cx = classNames.bind(styles);

const TextInput = (props) => {
  const style = props.style ?? {};
  if (props.width) {
    style.width = props.width;
  }

  return <input type="text" {...props} className={cx(['container', props.className])} style={style} />;
};

export default TextInput;
