import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('@console.m1ucs/token');
    const role = localStorage.getItem('@role');

    if (token !== null && role !== null) {
      navigate('/');
    }
  }, []);

  return children;
};

export default UnprotectedRoute;
