import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './MavLink.module.scss';
import API from '../../../../helpers/API';
import TextInput from '../../../../components/ui/TextInput';
import Button from '../../../../components/ui/Button';
import TopBar from '../../../../components/layout/TopBar';
import BottomBar from '../../../../components/layout/BottomBar';
import ContentWrapper from '../../../../components/layout/ContentWrapper';
import FormRow from '../../../../components/ui/FormRow';
import MavLinkMessages from './MavLinkMessages';

const cx = classNames.bind(styles);

const Page = () => {
  const { id, cid } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const mavLinkMessagesRef = useRef();

  useEffect(() => {
    // API: 명령 조회
    API.get(`/models/${id}/commands/${cid}`).then(({ success, data }) => {
      if (success) {
        setInfo(data);
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('이름이 입력되지 않았습니다.');
      return false;
    }
    if (isNaN(info.sysId) && info.sysId.trim() === '') {
      toast.error('SYS ID가 입력되지 않았습니다.');
      return false;
    }
    if (isNaN(info.compId) && info.compId.trim() === '') {
      toast.error('COMP ID가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      name: info.name,
      sysId: info.sysId,
      compId: info.compId,
      memo: info.memo,
      messages: mavLinkMessagesRef.current.getMessages(),
    };

    API.patch(`/models/${id}/commands/${cid}`, data).then(({ success }) => {
      if (success) {
        navigate(`/models/${id}`);
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  if (info === undefined) return;

  return (
    <div className={cx('container')}>
      <TopBar>Command Detail</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={320} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="SYS ID" required>
          <TextInput name="sysId" width={96} value={info.sysId} placeholder="SYS ID" onChange={handleChange} />
        </FormRow>
        <FormRow title="COMP ID" required>
          <TextInput name="compId" width={96} value={info.compId} placeholder="COMP ID" onChange={handleChange} />
        </FormRow>
        <FormRow title="Memo">
          <TextInput name="memo" width="100%" value={info.memo ?? ''} placeholder="Memo" onChange={handleChange} />
        </FormRow>
        <MavLinkMessages ref={mavLinkMessagesRef} />
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
