import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import API, { setAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    username: 'admin',
    password: '123qwer@',
  });
  const usernameRef = useRef();
  const passwordRef = useRef();

  // 화면 로딩 후, 아이디 입력 필드 포커스
  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'username':
          passwordRef.current.focus();
          break;

        case 'password':
          doLogin();
          break;

        default:
          break;
      }
    }
  };

  // 로그인
  const doLogin = async () => {
    // const { success, data } = await API.post('/login', {
    //   id: userInfo.username,
    //   password: userInfo.password,
    // });

    // if (success && data.accessToken) {
    //   setAuthToken(data.accessToken);
    //   localStorage.setItem('@role', data.role);
    //   navigate('/', { replace: true });
    // }

    setAuthToken('ACCESS_TOKEN');
    localStorage.setItem('@role', 'ADMIN');
    navigate('/', { replace: true });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo')} />
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <input
            ref={usernameRef}
            name="username"
            type="text"
            value={userInfo.username}
            placeholder="아이디"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="username"
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <input
            ref={passwordRef}
            name="password"
            type="password"
            value={userInfo.password}
            placeholder="비밀번호"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="current-password"
          />
        </div>
      </form>
      <div className={cx('button')} onClick={doLogin}>
        로그인
      </div>
    </div>
  );
};

export default Page;
