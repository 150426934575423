import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../../styles/form.module.scss';
import API from '../../../helpers/API';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import TopBar from '../../../components/layout/TopBar';
import BottomBar from '../../../components/layout/BottomBar';
import ContentWrapper from '../../../components/layout/ContentWrapper';
import FormRow from '../../../components/ui/FormRow';

const cx = classNames.bind(styles);

const Page = () => {
  const { id, tid } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState();

  useEffect(() => {
    // API: 텔레메트리상세조회
    API.get(`/models/${id}/telemetries/${tid}`).then(({ success, data }) => {
      if (success) {
        setInfo(data);
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const doDelete = () => {
    if (!confirm('삭제하시겠습니까?')) return;

    API.delete(`/models/${id}/telemetries/${tid}`).then(({ success }) => {
      if (success) {
        navigate(`/models/${id}`);
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (info.topic.trim() === '') {
      toast.error('Topic이 입력되지 않았습니다.');
      return false;
    }
    if (info.type.trim() === '') {
      toast.error('Type이 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      topic: info.topic,
      type: info.type,
      memo: info.memo,
    };

    API.patch(`/models/${id}/telemetries/${tid}`, data).then(({ success }) => {
      if (success) {
        navigate(`/models/${id}`);
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  if (info === undefined) return;

  return (
    <div className={cx('container')}>
      <TopBar>Command Detail</TopBar>
      <ContentWrapper>
        <FormRow title="Topic" required>
          <TextInput name="topic" width={240} value={info.topic} placeholder="Topic" onChange={handleChange} />
        </FormRow>
        <FormRow title="Type" required>
          <TextInput name="type" width={320} value={info.type} placeholder="Type" onChange={handleChange} />
        </FormRow>
        <FormRow title="Memo">
          <TextInput name="memo" width="100%" value={info.memo} placeholder="Memo" onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <BottomBar>
        <div>
          <Button title="Back" onClick={() => navigate(-1)} />
          <Button title="Delete" onClick={doDelete} />
        </div>
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
