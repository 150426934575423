import React from 'react';
import { Helmet } from 'react-helmet';

export const FontLoader = () => {
  if (process.env.REACT_APP_USE_WEBFONT === 'true') {
    return (
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&display=swap"
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link rel="stylesheet" href="./NotoSansKR.css" />
      <link rel="stylesheet" href="./NotoSansMono.css" />
    </Helmet>
  );
};

export default FontLoader;
