import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { IoCloseOutline } from 'react-icons/io5';

import styles from './SearchModel.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';

const cx = classNames.bind(styles);

const Modal = ({ callback }) => {
  const [models, setModels] = useState();

  useEffect(() => {
    API.get('/models').then(({ success, data }) => {
      setModels(success ? data : []);
    });
  }, []);

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.maker,
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'name',
      name: 'Model',
      exp: (item) => item.name,
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.agentType ?? '-',
      width: 120,
      align: 'center',
      onPress: callback,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Models</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={models} />
      </div>
    </div>
  );
};

export default Modal;
