import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { IoAddOutline, IoRemoveCircle, IoImageOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';
import FormRow from '../../components/ui/FormRow';
import SearchRobot from '../../components/modals/SearchRobot';

const cx = classNames.bind(styles);

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const [robots, setRobots] = useState();
  const [showRobotList, setShowRobotList] = useState(false);

  useEffect(() => {
    // API: 계정상세조회
    API.get(`/accounts/${id}`).then(({ success, data }) => {
      if (success) {
        setInfo({
          name: data.name,
          email: data.email,
          cellphone: data.cellphone,
        });
      }
    });

    // API: 계정로봇내역조회
    API.get(`/accounts/${id}/robots`).then(({ success, data }) => {
      if (success) {
        setRobots(data);
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const toggleRobotList = () => {
    setShowRobotList(!showRobotList);
  };

  const addRobot = (item) => {
    toggleRobotList();

    if (item) {
      const found = robots.find((robot) => robot.id === item.id);
      if (found === undefined) {
        // API: 계정로봇추가
        API.post(`/accounts/${id}/robots/${item.id}`).then(({ success }) => {
          if (success) {
            setRobots([...robots, item]);
          } else {
            toast.error('추가를 실패했습니다.');
          }
        });
      }
    }
  };

  const removeRobot = (item) => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 계정로봇삭제
    API.delete(`/accounts/${id}/robots/${item.id}`).then(({ success }) => {
      if (success) {
        setRobots(robots.filter((robot) => robot.id !== item.id));
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const doDelete = () => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 계정삭제
    API.delete(`/accounts/${id}`).then(({ success }) => {
      if (success) {
        navigate('/accounts');
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('성명이 입력되지 않았습니다.');
      return false;
    }
    if (info.email.trim() === '') {
      toast.error('이메일이 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      name: info.name,
      email: info.email,
      cellphone: info.cellphone,
    };

    API.patch(`/accounts/${id}`, data).then(({ success }) => {
      if (success) {
        navigate('/accounts');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const columns = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
    },
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.model.maker,
      style: { flex: 1 },
    },
    {
      key: 'model',
      name: 'Model',
      exp: (item) => item.model.name,
      style: { flex: 1 },
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.model.agentType ?? '-',
      width: 120,
      align: 'center',
    },
    {
      key: 'command',
      name: 'Command',
      exp: (item) => (item.commanderId === id ? <IoCheckmarkOutline size={22} /> : '-'),
      width: 88,
      align: 'center',
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: removeRobot,
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>Account Detail</TopBar>
      <ContentWrapper>
        {info && (
          <div>
            <FormRow title="Name" required>
              <TextInput name="name" width={160} value={info.name} placeholder="Name" onChange={handleChange} />
            </FormRow>
            <FormRow title="Email" required>
              <TextInput name="email" width={320} value={info.email} placeholder="Email" onChange={handleChange} />
            </FormRow>
            <FormRow title="Cellphone">
              <TextInput
                name="cellphone"
                width={160}
                value={info.cellphone}
                placeholder="Cellphone"
                onChange={handleChange}
              />
            </FormRow>
            <FormRow title="Robot">
              <div className={cx('tableWrapperCell')}>
                <div className={cx('tableControl')}>
                  <div className={cx(['button', 'icon'])} onClick={toggleRobotList}>
                    <IoAddOutline size={20} />
                  </div>
                  {showRobotList && (
                    <div className={cx('panelWrapper')}>
                      <SearchRobot callback={addRobot} />
                    </div>
                  )}
                </div>
                {robots?.length > 0 && (
                  <div className={cx('tableWrapper')}>
                    <Table absoluteFill={false} columns={columns} data={robots} />
                  </div>
                )}
              </div>
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <BottomBar>
        <div>
          <Button title="Back" onClick={() => navigate(-1)} />
          <Button title="Remove" onClick={doDelete} />
        </div>
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
