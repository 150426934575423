import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoImageOutline, IoSearchOutline } from 'react-icons/io5';
import commaNumber from 'comma-number';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';
import FormRow from '../../components/ui/FormRow';
import SearchModel from '../../components/modals/SearchModel';
import SearchAccount from '../../components/modals/SearchAccount';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: '',
    model: null,
    commander: '',
    color: '',
    thumbnail: null,
  });
  const [showModelList, setShowModelList] = useState(false);
  const [showAccountList, setShowAccountList] = useState(false);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const toggleCheckbox = (name) => {
    setInfo({
      ...info,
      [name]: !info[name],
    });
  };

  const handleFileInput = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.files[0],
    });
  };

  const toggleModelList = () => {
    setShowModelList(!showModelList);
  };

  const toggleAccountList = () => {
    setShowAccountList(!showAccountList);
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('로봇명이 입력되지 않았습니다.');
      return false;
    }
    if (info.model === null) {
      toast.error('모델이 선택되지 않았습니다.');
      return false;
    }
    if (info.color.trim() === '') {
      toast.error('색상이 입력되지 않았습니다.');
      return false;
    }
    return true;
  };

  const selectModel = (selected) => {
    toggleModelList();

    if (selected) {
      setInfo({
        ...info,
        model: selected,
      });
    }
  };

  const selectAccount = (selected) => {
    toggleAccountList();

    if (selected) {
      setInfo({
        ...info,
        commander: selected,
      });
    }
  };

  const doSave = () => {
    if (!validation()) return;

    const formData = new FormData();
    formData.append('name', info.name);
    formData.append('modelId', info.model.id);
    formData.append('commanderId', info.commander.id);
    formData.append('color', info.color);
    if (info.thumbnail) {
      formData.append('thumbnail', info.thumbnail);
    }

    API.post('/robots', formData).then(({ success }) => {
      if (success) {
        navigate('/robots');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const modelColumns = [
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.maker,
      style: { flex: 1 },
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.agentType ?? '-',
      width: 120,
      align: 'center',
    },
    {
      key: 'iconUrl',
      name: 'Icon',
      exp: (item) => (item.iconUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.iconUrl && window.open(item.iconUrl),
    },
    {
      key: 'thumbnailUrl',
      name: 'Thumb',
      exp: (item) => (item.thumbnailUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.thumbnailUrl && window.open(item.thumbnailUrl),
    },
  ];

  const accountColumns = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      width: 120,
      align: 'center',
    },
    {
      key: 'email',
      name: 'Email',
      exp: (item) => item.email ?? '-',
      style: { flex: 1 },
    },
    {
      key: 'robots',
      name: 'Robots',
      exp: (item) => commaNumber(item.robots.length),
      width: 88,
      align: 'center',
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>New Robot</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={240} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="Model" required>
          <div className={cx('tableWrapperCell')}>
            <div className={cx('tableControl')}>
              <div className={cx(['button', 'icon'])} onClick={toggleModelList}>
                <IoSearchOutline size={16} />
              </div>
              {showModelList && (
                <div className={cx('panelWrapper')}>
                  <SearchModel callback={selectModel} />
                </div>
              )}
            </div>
            {info.model && (
              <div className={cx('tableWrapper')}>
                <Table absoluteFill={false} columns={modelColumns} data={[info.model]} />
              </div>
            )}
          </div>
        </FormRow>
        <FormRow title="Commander">
          <div className={cx('tableWrapperCell')}>
            <div className={cx('tableControl')}>
              <div className={cx(['button', 'icon'])} onClick={toggleAccountList}>
                <IoSearchOutline size={16} />
              </div>
              {showAccountList && (
                <div className={cx('panelWrapper')}>
                  <SearchAccount callback={selectAccount} />
                </div>
              )}
            </div>
            {info.commander && (
              <div className={cx('tableWrapper')}>
                <Table absoluteFill={false} columns={accountColumns} data={[info.commander]} />
              </div>
            )}
          </div>
        </FormRow>
        <FormRow title="Color" required>
          <TextInput name="color" width={120} value={info.color} placeholder="Color" onChange={handleChange} />
        </FormRow>
        <FormRow title="Thumbnail">
          <input name="thumbnail" type="file" onChange={handleFileInput} className={cx('attach')} />
        </FormRow>
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
