import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoChevronForwardOutline, IoRemoveCircle, IoCaretUpOutline, IoCaretDownOutline } from 'react-icons/io5';

import styles from './MavLinkMessages.module.scss';
import API from '../../../../helpers/API';
import TextInput from '../../../../components/ui/TextInput';
import Table from '../../../../components/ui/Table';
import Select from '../../../../components/ui/Select';
import Checkbox from '../../../../components/ui/Checkbox';
import FormSectionTitle from '../../../../components/ui/FormSectionTitle';

const cx = classNames.bind(styles);

const MavLinkMessages = (_, ref) => {
  const { id, cid } = useParams();
  const [messages, setMessages] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [defMavLinkMessages, setDefMavLinkMessages] = useState();

  useImperativeHandle(ref, () => ({
    getMessages: () => messages,
  }));

  useEffect(() => {
    API.get('/mavlink/messages').then(({ success, data }) => {
      setDefMavLinkMessages(success ? data : []);
    });

    loadMessages();
  }, []);

  const loadMessages = () => {
    API.get(`/models/${id}/commands/${cid}/messages`).then(({ success, data }) => {
      if (success) {
        setMessages(data.sort((a, b) => (a.seq < b.seq ? -1 : 1)));
      } else {
        setMessages([]);
      }
    });
  };

  //#region 메시지 관련 이벤트
  // 메시지 선택
  const selectMessage = (index) => {
    setSelectedIndex(index);
  };

  // 메시지 반복 선택
  const toggleMultiple = (index) => {
    messages[index].isMultiple = !messages[index].isMultiple;
    setMessages([...messages]);
  };

  const up = (index) => {
    if (index === 0) return;

    const message = messages.splice(index, 1)[0];
    messages.splice(index - 1, 0, message);
    messages.forEach((message, index) => (message.seq = index));
    setMessages([...messages]);

    if (index === selectedIndex) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const down = (index) => {
    if (index === messages.length - 1) return;

    const message = messages.splice(index, 1)[0];
    messages.splice(index + 1, 0, message);
    setMessages([...messages]);

    if (index === selectedIndex) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  // 메시지 삭제
  const removeMessage = (message, index) => {
    API.delete(`/models/${id}/commands/${cid}/messages/${message.id}`).then(({ success }) => {
      if (success) {
        messages.splice(index, 1);
        setMessages([...messages]);

        if (index === selectedIndex) {
          setSelectedIndex();
        }
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  // 메시지 추가
  const addMessage = (e) => {
    const found = defMavLinkMessages.find((mavLinkMessage) => mavLinkMessage.id === Number(e.target.value));
    const data = {
      id: found.id,
      name: found.name,
      fields: found.fields.map(({ seq, name }) => ({ seq, name })),
    };

    API.post(`/models/${id}/commands/${cid}/messages`, data).then(({ success }) => {
      if (success) {
        loadMessages();
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  // 메시지 메모 변경
  const changeMemo = (e) => {
    messages[selectedIndex].memo = e.target.value;
    setMessages([...messages]);
  };
  //#endregion

  //#region 필드 관련 이벤트
  // 필드 값 변경
  const changeFieldValue = (e, index) => {
    messages[selectedIndex].fields[index].value = e.target.value;
    setMessages([...messages]);
  };

  // 필드 변수사용 선택
  const toggleVariable = (index) => {
    messages[selectedIndex].fields[index].isVariable = !messages[selectedIndex].fields[index].isVariable;
    setMessages([...messages]);
  };
  //#endregion

  //#region 테이블 컬럼 정의
  const messagesTableColumns = [
    {
      key: 'no',
      name: 'No',
      exp: (_, index) => index + 1,
      width: 56,
      align: 'center',
      onPress: (_, index) => selectMessage(index),
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item, index) => (index === selectedIndex ? <strong>{item.name}</strong> : item.name),
      style: { flex: 1 },
      onPress: (_, index) => selectMessage(index),
    },
    {
      key: 'id',
      name: 'ID',
      exp: (item) => item.mavlinkMessageId,
      width: 56,
      align: 'center',
      onPress: (_, index) => selectMessage(index),
    },
    {
      key: 'isMultiple',
      name: 'Multiple',
      exp: (item, index) => <Checkbox checked={item.isMultiple} onClick={() => toggleMultiple(index)} />,
      width: 88,
      align: 'center',
    },
    {
      key: 'order',
      name: 'Order',
      exp: (_, index) => (
        <div className={cx('arrows')}>
          <IoCaretUpOutline size={20} onClick={() => up(index)} />
          <IoCaretDownOutline size={20} onClick={() => down(index)} />
        </div>
      ),
      width: 88,
      align: 'center',
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (_) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: (item, index) => removeMessage(item, index),
    },
  ];

  const fieldsTableColumns = [
    {
      key: 'no',
      name: 'No',
      exp: (item) => item.seq + 1,
      width: 48,
      align: 'center',
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
    },
    {
      key: 'value',
      name: 'Value',
      exp: (item, index) => (
        <TextInput
          width={72}
          disabled={item.isVariable}
          value={item.value}
          onChange={(e) => changeFieldValue(e, index)}
          className={cx('tableField')}
        />
      ),
      width: 88,
      align: 'center',
    },
    {
      key: 'isVariable',
      name: 'Variable',
      exp: (item, index) => <Checkbox checked={item.isVariable} onClick={() => toggleVariable(index)} />,
      width: 88,
      align: 'center',
    },
  ];
  //#endregion

  const fields = useMemo(() => {
    if (selectedIndex === undefined) return;

    return [...messages[selectedIndex].fields];
  }, [messages, selectedIndex]);

  return (
    <div className={cx('container')}>
      <FormSectionTitle title="MavLink Messages" />
      <div className={cx('columns')}>
        <div className={cx('column')}>
          <Table absoluteFill={false} columns={messagesTableColumns} data={messages} />
          <div className={cx('padding')}>
            <Select width="100%" value="" onChange={addMessage}>
              <option value="">Select</option>
              {defMavLinkMessages?.map((mavLinkMessage) => (
                <option key={mavLinkMessage.id} value={mavLinkMessage.id}>
                  {mavLinkMessage.name} ({mavLinkMessage.id})
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className={cx('center')}>
          <div className={cx('button')}>
            <IoChevronForwardOutline size={20} color="white" />
          </div>
        </div>
        <div className={cx('column')}>
          {selectedIndex !== undefined && <Table absoluteFill={false} columns={fieldsTableColumns} data={fields} />}
          {selectedIndex !== undefined && (
            <div className={cx('padding')}>
              <textarea
                name="memo"
                placeholder="Memo"
                value={messages[selectedIndex].memo}
                onChange={changeMemo}
                className={cx('textarea')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(MavLinkMessages);
