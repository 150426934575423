import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import classNames from 'classnames/bind';
import produce from 'immer';
import { IoCaretUpOutline, IoCaretDownOutline } from 'react-icons/io5';

import styles from './Table.module.scss';
import EmptyContainer from './EmptyContainer';
import Checkbox from './Checkbox';

const cx = classNames.bind(styles);

const Table = ({ useCheck = false, absoluteFill = true, thin = false, columns, data, style = {} }, ref) => {
  const [rows, setRows] = useState(data);
  const [sort, setSort] = useState();

  useImperativeHandle(ref, () => ({
    // 선택된 데이터 획득
    getCheckedData: () =>
      rows
        .filter((row) => row._checked)
        .map((item) =>
          produce(item, (draft) => {
            delete draft._checked;
            delete draft._originIndex;
          }),
        ),
  }));

  useEffect(() => {
    if (data === undefined) return;

    setRows(data.map((item, index) => ({ ...item, _checked: false, _originIndex: index })));
  }, [data]);

  useEffect(() => {
    if (sort === undefined) return;

    setRows(
      produce(rows, (draft) => {
        const column = columns.find((column) => column.key === sort.key);
        const sortFunc = column?.sort ?? ((item) => item._originIndex);
        const asc = sort.order === 'asc' ? 1 : -1;

        draft.sort((a, b) => {
          const aValue = sortFunc(a) ?? '!';
          const bValue = sortFunc(b) ?? '!';
          return (aValue < bValue ? -1 : 1) * asc;
        });
      }),
    );
  }, [sort]);

  const toggleSort = (key) => {
    if (sort === undefined || sort.key !== key) {
      setSort({ key, order: 'asc' });
    } else if (sort.key === key) {
      if (sort.order === 'asc') {
        setSort({ key, order: 'desc' });
      } else {
        setSort({ key: '_originIndex', order: 'asc' });
      }
    }
  };

  // 전체 선택
  const checkAll = () => {
    setRows(
      produce(rows, (draft) => {
        draft.forEach((item) => (item._checked = !checkedAll));
      }),
    );
  };

  // 개별 선택
  const checkRow = (index) => {
    setRows(
      produce(rows, (draft) => {
        draft[index]._checked = !draft[index]._checked;
      }),
    );
  };

  // 전체 선택 여부
  const checkedAll = useMemo(() => {
    if (rows === undefined || rows.length === 0) return false;

    return rows.reduce((acc, cur) => acc && cur._checked, true);
  }, [rows]);

  return (
    <div style={style} className={cx(['container', { absoluteFill, thin }])}>
      <div className={cx('header')}>
        {useCheck && (
          <div className={cx(['column', 'check'])}>
            <Checkbox checked={checkedAll} onClick={checkAll} />
          </div>
        )}
        {columns?.map((column) => {
          const style = column.style ?? {};
          if (column.width) {
            style.flexBasis = column.width;
          }

          // 정렬 가능 여부
          const isSortable = column.sort !== undefined;

          return (
            <div
              key={column.key}
              onClick={isSortable ? () => toggleSort(column.key) : null}
              className={cx(['column', column.align ?? 'left', { hidden: column.hidden, pointer: isSortable }])}
              style={style}>
              {column.name}
              {sort?.key === column.key && sort.order === 'asc' && <IoCaretUpOutline />}
              {sort?.key === column.key && sort.order === 'desc' && <IoCaretDownOutline />}
            </div>
          );
        })}
      </div>
      {rows?.length === 0 && <EmptyContainer icon />}
      {rows?.length > 0 && (
        <div className={cx('body')}>
          {rows.map((row, index) => (
            <div key={index} className={cx('row')}>
              {useCheck && (
                <div className={cx(['column', 'check'])}>
                  <Checkbox checked={row._checked} onClick={() => checkRow(index)} />
                </div>
              )}
              {columns.map((column) => {
                const style = column.style ? column.style : {};
                if (column.width) {
                  style.flexBasis = column.width;
                }

                return (
                  <div
                    key={column.key}
                    className={cx([
                      'column',
                      column.align ?? 'left',
                      { hidden: column.hidden, pointer: column.onPress },
                    ])}
                    style={style}
                    onClick={() => {
                      if (column.onPress) {
                        column.onPress(row, index);
                      }
                    }}>
                    {column.exp(row, index)}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default forwardRef(Table);
