import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './new.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';
import FormRow from '../../components/ui/FormRow';
import FormRowInfo from '../../components/ui/FormRowInfo';
import Select from '../../components/ui/Select';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: '',
    maker: '',
    agentType: '',
    icon: null,
    thumbnail: null,
  });

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInput = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.files[0],
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('모델명이 입력되지 않았습니다.');
      return false;
    }
    if (info.maker.trim() === '') {
      toast.error('제조사가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const formData = new FormData();
    formData.append('name', info.name);
    formData.append('maker', info.maker);
    formData.append('agentType', info.agentType);
    formData.append('icon', info.icon);
    formData.append('thumbnail', info.thumbnail);

    API.post('/models', formData).then(({ success }) => {
      if (success) {
        navigate('/models');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <TopBar>New Model</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={240} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="Maker" required>
          <TextInput name="maker" width={240} value={info.maker} placeholder="Maker" onChange={handleChange} />
        </FormRow>
        <FormRow title="Agent Type">
          <Select width={120} name="agentType" value={info.agentType} onChange={handleChange}>
            <option value="">Select</option>
            <option value="MavLink">MavLink</option>
            <option value="ROS">ROS</option>
            <option value="IoT">IoT</option>
          </Select>
        </FormRow>
        <FormRow title="Icon" required>
          <input name="icon" type="file" onChange={handleFileInput} className={cx('attach')} />
        </FormRow>
        <FormRow title="Thumbnail" required>
          <input name="thumbnail" type="file" onChange={handleFileInput} className={cx('attach')} />
        </FormRow>
        {['MavLink', 'ROS'].includes(info.agentType) && (
          <FormRow title="Commands">
            <FormRowInfo>등록 후 설정할 수 있습니다.</FormRowInfo>
          </FormRow>
        )}
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
