import React from 'react';
import classNames from 'classnames/bind';
import { IoCheckmarkOutline } from 'react-icons/io5';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

const Checkbox = ({ checked, onClick }) => {
  return (
    <div className={cx('container')} onClick={onClick}>
      {checked && <IoCheckmarkOutline size={22} />}
    </div>
  );
};

export default Checkbox;
