import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import TopBar from '../../../components/layout/TopBar';
import BottomBar from '../../../components/layout/BottomBar';
import ContentWrapper from '../../../components/layout/ContentWrapper';
import FormRow from '../../../components/ui/FormRow';
import Select from '../../../components/ui/Select';
import CommandList from './CommandList';
import TelemetryList from './TelemetryList';

const cx = classNames.bind(styles);

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const [isChangeIcon, setChangeIcon] = useState(false);
  const [isChangeThumbnail, setChangeThumbnail] = useState(false);

  useEffect(() => {
    // API: 모델상세조회
    API.get(`/models/${id}`).then(({ success, data }) => {
      if (success) {
        setInfo(data);
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInput = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.files[0],
    });
  };

  const open = (url) => {
    window.open(url);
  };

  const doDelete = () => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 모델삭제
    API.delete(`/models/${id}`).then((result) => {
      if (result.success) {
        navigate('/models');
      } else if (result.data.message.startsWith('robot exist that')) {
        toast.error('이 모델을 사용 중인 로봇이 있습니다.');
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('모델명이 입력되지 않았습니다.');
      return false;
    }
    if (info.maker.trim() === '') {
      toast.error('제조사가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const formData = new FormData();
    formData.append('name', info.name);
    formData.append('maker', info.maker);
    formData.append('agentType', info.agentType);
    if (info.icon && isChangeIcon) {
      formData.append('icon', info.icon);
    }
    if (info.thumbnail && isChangeThumbnail) {
      formData.append('thumbnail', info.thumbnail);
    }

    API.patch(`/models/${id}`, formData).then(({ success }) => {
      if (success) {
        navigate('/models');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  if (info === undefined) return;

  return (
    <div className={cx('container')}>
      <TopBar>Model Detail</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={240} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="Maker" required>
          <TextInput name="maker" width={240} value={info.maker} placeholder="Maker" onChange={handleChange} />
        </FormRow>
        <FormRow title="Agent Type">
          <Select width={120} name="agentType" readOnly value={info.agentType}>
            <option value="">Select</option>
            <option value="MavLink">MavLink</option>
            <option value="ROS">ROS</option>
            <option value="IoT">IoT</option>
          </Select>
        </FormRow>
        <FormRow title="Icon" required>
          {info.iconUrl && !isChangeIcon && (
            <>
              <Button title="Open" onClick={() => open(info.iconUrl)} />
              <Button title="Remove" onClick={() => setChangeIcon(true)} style={{ marginLeft: 8 }} />
            </>
          )}
          {(!info.iconUrl || isChangeIcon) && (
            <input name="icon" type="file" onChange={handleFileInput} className={cx('attach')} />
          )}
        </FormRow>
        <FormRow title="Thumbnail" required>
          {info.thumbnailUrl && !isChangeThumbnail && (
            <>
              <Button title="Open" onClick={() => open(info.thumbnailUrl)} />
              <Button title="Remove" onClick={() => setChangeThumbnail(true)} style={{ marginLeft: 8 }} />
            </>
          )}
          {(!info.thumbnailUrl || isChangeThumbnail) && (
            <input name="thumbnail" type="file" onChange={handleFileInput} className={cx('attach')} />
          )}
        </FormRow>
        {['MavLink', 'ROS'].includes(info.agentType) && (
          <FormRow title="Commands">
            <CommandList agentType={info.agentType} />
          </FormRow>
        )}
        {info.agentType === 'ROS' && (
          <FormRow title="Telemetries">
            <TelemetryList />
          </FormRow>
        )}
      </ContentWrapper>
      <BottomBar>
        <div>
          <Button title="Back" onClick={() => navigate(-1)} />
          <Button title="Delete" onClick={doDelete} />
        </div>
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
