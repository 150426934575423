import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { toast } from 'react-toastify';

import styles from './TelemetryList.module.scss';
import API from '../../../helpers/API';
import Table from '../../../components/ui/Table';

const cx = classNames.bind(styles);

const TelemetryList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [telemetries, setTelemetries] = useState();

  useEffect(() => {
    // API: 텔레메트리 내역 조회
    API.get(`/models/${id}/telemetries`).then(({ success, data }) => {
      if (success) {
        setTelemetries(data);
      }
    });
  }, [id]);

  const moveToForm = () => {
    navigate(`/models/${id}/telemetries/new`);
  };

  const doDelete = (item) => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 명령삭제
    API.delete(`/models/${id}/telemetries/${item.id}`).then(({ success }) => {
      if (success) {
        setTelemetries(telemetries.filter((telemetry) => telemetry.id !== item.id));
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const columns = [
    {
      key: 'topic',
      name: 'Topic',
      exp: (item) => <span className={cx(['monospace', 'blue'])}>{item.topic}</span>,
      width: 280,
      onPress: (item) => navigate(`/models/${id}/telemetries/${item.id}`),
    },
    {
      key: 'type',
      name: 'Type',
      exp: (item) => <span className={cx('monospace')}>{item.type}</span>,
      width: 320,
      onPress: (item) => navigate(`/models/${id}/telemetries/${item.id}`),
    },
    {
      key: 'memo',
      name: 'Memo',
      exp: (item) => item.memo,
      style: { flex: 1 },
      onPress: (item) => navigate(`/models/${id}/telemetries/${item.id}`),
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: doDelete,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('tableControl')}>
        <div className={cx(['button', 'icon'])} onClick={moveToForm}>
          <IoAddOutline size={20} />
        </div>
      </div>
      <div className={cx('tableWrapper')}>
        <Table absoluteFill={false} columns={columns} data={telemetries} />
      </div>
    </div>
  );
};
export default TelemetryList;
