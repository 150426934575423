import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../../../helpers/API';
import MavLink from './MavLink';
import ROS from './ROS';

const Page = () => {
  const { id } = useParams();
  const [agentType, setAgentType] = useState();

  useEffect(() => {
    API.get(`/models/${id}`).then(({ success, data }) => {
      if (success) {
        setAgentType(data.agentType);
      }
    });
  }, [id]);

  if (agentType === 'MavLink') {
    return <MavLink />;
  } else if (agentType === 'ROS') {
    return <ROS />;
  }

  return null;
};

export default Page;
