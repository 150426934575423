import React from 'react';
import classNames from 'classnames/bind';

import styles from './BottomBar.module.scss';

const cx = classNames.bind(styles);

const BottomBar = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

export default BottomBar;
