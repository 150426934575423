import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { toast } from 'react-toastify';

import styles from './MetadataList.module.scss';
import API from '../../../helpers/API';
import Table from '../../../components/ui/Table';

const cx = classNames.bind(styles);

const CommandList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState();

  useEffect(() => {
    // API: 메타데이터 내역 조회
    API.get(`/robots/${id}/metadata`).then(({ success, data }) => {
      if (success) {
        setMetadata(data);
      }
    });
  }, [id]);

  const moveToForm = () => {
    navigate(`/robots/${id}/metadata/new`);
  };

  const doDelete = (item) => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 메타데이터 삭제
    API.delete(`/robots/${id}/metadata/${item.id}`).then(({ success }) => {
      if (success) {
        setMetadata(metadata.filter(({ id }) => id !== item.id));
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const columns = [
    {
      key: 'key',
      name: 'Key',
      exp: (item) => <span className={cx(['monospace', 'blue'])}>{item.key}</span>,
      width: 280,
      onPress: (item) => navigate(`/robots/${id}/metadata/${item.id}`),
    },
    {
      key: 'value',
      name: 'Value',
      exp: (item) => <span className={cx('monospace')}>{item.value}</span>,
      width: 320,
      onPress: (item) => navigate(`/robots/${id}/metadata/${item.id}`),
    },
    {
      key: 'memo',
      name: 'Memo',
      exp: (item) => item.memo,
      style: { flex: 1 },
      onPress: (item) => navigate(`/robots/${id}/metadata/${item.id}`),
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: doDelete,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('tableControl')}>
        <div className={cx(['button', 'icon'])} onClick={moveToForm}>
          <IoAddOutline size={20} />
        </div>
      </div>
      <div className={cx('tableWrapper')}>
        <Table absoluteFill={false} columns={columns} data={metadata} />
      </div>
    </div>
  );
};
export default CommandList;
