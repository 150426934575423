import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import { IoImageOutline } from 'react-icons/io5';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [robots, setRobots] = useState();
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    API.get('/robots').then(({ success, data }) => {
      if (success) {
        data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;

          if (a.model.maker > b.model.maker) return 1;
          if (a.model.maker < b.model.maker) return -1;

          if (a.model.name > b.model.name) return 1;
          if (a.model.name < b.model.name) return -1;

          return 0;
        });
        setRobots(data);
      } else {
        setRobots([]);
      }
    });

    API.get('/accounts').then(({ success, data }) => {
      if (success) {
        setAccounts(data);
      }
    });
  }, []);

  const moveToForm = () => {
    navigate('/robots/new');
  };

  const columns = [
    {
      key: 'id',
      name: 'ID',
      exp: (item) => <span className={cx('monospace')}>{item.id.substring(0, 8)}</span>,
      align: 'center',
      width: 120,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.id;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.model.maker,
      style: { flex: 1 },
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'model',
      name: 'Model',
      exp: (item) => item.model.name,
      style: { flex: 1 },
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.model.agentType ?? '-',
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'commander',
      name: 'Commander',
      exp: (item) => accounts?.find((user) => user.id === item.commanderId)?.name ?? '-',
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'color',
      name: 'Color',
      exp: (item) => <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: item.color }} />,
      align: 'center',
      width: 88,
      onPress: (item) => navigate(`/robots/${item.id}`),
    },
    {
      key: 'thumbnailUrl',
      name: 'Thumb',
      exp: (item) => (item.thumbnailUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.thumbnailUrl && window.open(item.thumbnailUrl),
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>{robots && `Total ${commaNumber(robots.length)}`}</TopBar>
      <ContentWrapper>
        <Table columns={columns} data={robots} style={{ minWidth: 1680 }} />
      </ContentWrapper>
      <BottomBar>
        <div />
        <div>
          <Button title="New" onClick={moveToForm} />
        </div>
      </BottomBar>
    </div>
  );
};

export default Page;
