import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { IoCloseOutline } from 'react-icons/io5';

import styles from './SearchModel.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';

const cx = classNames.bind(styles);

const Modal = ({ callback }) => {
  const [robots, setRobots] = useState();

  useEffect(() => {
    API.get('/robots').then(({ success, data }) => {
      setRobots(success ? data : []);
    });
  }, []);

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'model',
      name: 'Model',
      exp: (item) => item.model.name,
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'name',
      name: 'Robot',
      exp: (item) => item.name,
      style: { flex: 1 },
      onPress: callback,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Robots</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={robots} />
      </div>
    </div>
  );
};

export default Modal;
