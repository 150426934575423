import axios from 'axios';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const token = localStorage.getItem('@console.m1ucs/token');
if (token) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Response intercept
axiosInstance.interceptors.response.use(
  ({ data }) => {
    // Invalid access token
    if (!data.success && data.data.message === 'invalid access token') {
      localStorage.removeItem('@console.m1ucs/token');
      window.location.href = '/login';
      return;
    }

    return data;
  },
  (error) => {
    return {
      success: false,
      data: { error },
    };
  },
);

export default axiosInstance;

export const setAuthToken = (newToken) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  localStorage.setItem('@console.m1ucs/token', newToken);
};

export const removeAuthToken = () => {
  delete axiosInstance.defaults.headers.common['Authorization'];
  localStorage.clear();
};
