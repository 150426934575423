import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../styles/form.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';
import FormRow from '../../components/ui/FormRow';
import FormRowInfo from '../../components/ui/FormRowInfo';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: '',
    email: '',
    password: '',
    cellphone: '',
  });

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('성명이 입력되지 않았습니다.');
      return false;
    }
    if (info.email.trim() === '') {
      toast.error('이메일이 입력되지 않았습니다.');
      return false;
    }
    if (info.password.trim() === '') {
      toast.error('비밀번호가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      name: info.name,
      email: info.email,
      password: info.password,
      cellphone: info.cellphone,
    };

    API.post('/accounts', data).then(({ success }) => {
      if (success) {
        navigate('/accounts');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <TopBar>New Account</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={160} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="Email" required>
          <TextInput name="email" width={320} value={info.email} placeholder="Email" onChange={handleChange} />
        </FormRow>
        <FormRow title="Password" required>
          <TextInput
            name="password"
            width={160}
            type="password"
            value={info.password}
            placeholder="Password"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="Cellphone">
          <TextInput
            name="cellphone"
            width={160}
            value={info.cellphone}
            placeholder="Cellphone"
            onChange={handleChange}
          />
        </FormRow>
        <FormRow title="Robot">
          <FormRowInfo>등록 후 설정할 수 있습니다.</FormRowInfo>
        </FormRow>
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
