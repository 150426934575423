import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import CodeEditor from '@uiw/react-textarea-code-editor';

import styles from './CodeEditor.module.scss';

const cx = classNames.bind(styles);

const Editor = (props) => {
  const editorRef = useRef();

  const focus = () => {
    editorRef.current.focus();
  };

  return (
    <div className={cx('container')} onClick={focus}>
      <CodeEditor ref={editorRef} {...props} className={cx('codeEditor')} />
    </div>
  );
};

export default Editor;
